$(function () {
  window.submitDnaInvisibleRecaptcha = function () {
    $('.invisible-recaptcha-form').off('submit');
    $('.invisible-recaptcha-form').submit();
    InvisibleRecaptchaForm();
  };

  const InvisibleRecaptchaForm = function () {
    $('.invisible-recaptcha-form').on('submit', function (ev) {
      ev.preventDefault();
      $(this).find(':submit').attr('disabled', true);
      setTimeout(() => {
        grecaptcha.execute();
      }, 100);
    });
  };
});
